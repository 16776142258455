.container {
    border: 1px solid #7d00d4;
    border-radius: 8px;
    padding: 8px;

    .header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px;
        gap: 8px;

        .info {
            flex: 1 1;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;

            .primary {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 16px;

                .name {
                    color: #1a1c23;
                    font-weight: bold;
                }

                .divider {
                    padding: 0 6px;
                }

                .price {
                    color: #1a1c23;
                }
            }

            .secondary {
                margin-top: 4px;
                color: #616475;
                font-size: 14px;
            }

            .ticket {
                margin-top: 4px;
                color: #1a1c23;
                font-size: 14px;
            }
        }
    }

    .accordionHeader {
        padding: 0 6px;

        .icon {
            border-radius: 36px;
            border: 1px solid #ddd;
        }
    }

    .itemHeader {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .info {
            flex: 1 1;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;

            .title {
            }

            .date {
                color: #616475;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
            }
        }

        .label {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 4px 8px;
        }
    }

    .detailsContainer {
        padding: 8px 0 0;
    }

    .details {
        .orders {
            border: 1px solid #ddd;
            border-radius: 6px;
            padding: 0 0 6px;

            &.highlighted {
                border-color: #7d00d4;
            }

            .note {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;
                padding-bottom: 2px;

                .label {
                    padding: 2px 6px;
                    font-weight: bold;
                    flex: 1 1;
                    font-size: 0.9em;
                    color: #9b9eab;
                    text-align: left;
                }

                .value {
                    padding: 6px;
                    margin: 0 8px;
                    width: 100%;
                    background-color: rgba(252, 232, 0, 0.2);
                }
            }
        }
    }
}
