@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&display=swap');
@import 'https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&display=swap';

@font-face {
    font-family: 'Montserrat';
    src: url('/fonts/Montserrat-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/fonts/Montserrat-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

html,
body {
    padding: 0;
    margin: 0;
    font-family: Montserrat, Quicksand, Roboto, Helvetica, Arial, sans-serif;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

[data-test-class='menus-modal']::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}

[data-test-class='sort-product-modal']::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}
