.content {
    position: relative;
    min-width: 400px;
    height: 100%;

    iframe {
        width: 100%;
        height: 100%;
        border: none;
    }

    .loading {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 10;
        background-color: #fafafa;

        display: flex;
        align-items: center;
        justify-content: center;
    }
}
